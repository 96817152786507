import { Container, Text, Stack } from "@chakra-ui/react"
import { NavBar } from "../components/navbar"

export const Teaching = () => {
    return (
        <>
            <NavBar selectedId={4} />
            <Container maxW={"4xl"} pb={20} mt={{ base: 8, md: 10 }}>
                <Text textAlign={"center"} fontSize={{ base: "2xl", md: "4xl" }} fontWeight={600}>Teaching</Text>
                <Stack px={{ base: 3, md: 0 }} as="ul" spacing={4} pt={10} fontSize={"lg"}>
                    <Text as="li">CSCI 1110: Introduction to Computer Science</Text>
                    <Text as="li">CSCI 1170: Introduction to Web Design and Development</Text>
                    <Text as="li">CSCI 4177 & 5709: Advanced Topics in Web Development</Text>
                    <Text as="li">CSCI 5601: Designing for User Experience</Text>
                </Stack>
            </Container>
        </>
    )
}