import { ChakraProvider } from '@chakra-ui/react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { Home } from './pages/home';
import { ResearchAreas } from './pages/research_areas';
import { Publications } from './pages/publications';
import { Teaching } from './pages/teaching';
import { Contact } from './pages/contact';

function App() {
  const router = createBrowserRouter(
    [
      { path: '/', element: <Home /> },
      { path: '/research-areas', element: <ResearchAreas /> },
      { path: '/publications', element: <Publications /> },
      { path: '/teaching', element: <Teaching /> },
      { path: '/contact', element: <Contact /> },
    ]
  );

  return (
    <ChakraProvider>
      <RouterProvider router={router} />
    </ChakraProvider>
  );
}

export default App;
