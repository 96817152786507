import { Box, Container, Text, Stack, SimpleGrid } from "@chakra-ui/react"
import { NavBar } from "../components/navbar"
import humanCentredImg from "../assets/images/img_hcai.png";
import adaptSysImg from "../assets/images/img_user_adapt_sys.png";
import persuasiveSysImg from "../assets/images/img_persuasive_sys.png";
import userModelImg from "../assets/images/img_user_model.png";
import intDesignImg from "../assets/images/img_intervention_des.png";
import digitalHealthImg from "../assets/images/img_digital_health.png";

const Area = ({ title, text, icon, bgColor = "gray.400", wh = "350px 200px" }) => {
    return (
        <Stack>
            <Box
                w={"100%"}
                h={"200px"}
                align={"center"}
                justify={"center"}
                color={"white"}
                roundedTopLeft={20}
                roundedTopRight={20}
                bg={bgColor}
                mb={1}
                bgImage={icon}
                bgPosition={"center"}
                bgSize={wh}
                bgRepeat={"no-repeat"}
            >
            </Box>
            <Text fontWeight={600} fontSize={"lg"}>{title}</Text>
            <Text color={"gray.600"}>{text}</Text>
        </Stack>
    )
}

export const ResearchAreas = () => {
    return (
        <Box id="r">
            <NavBar selectedId={1} />
            <Container maxW={"6xl"} pb={20}>
                <Text textAlign={"center"} mt={{ base: 8, md: 10 }} fontSize={{ base: "2xl", md: "4xl" }} fontWeight={600}>My Research Areas</Text>
                <Box pt={{ base: 8, md: 16 }}>
                    <SimpleGrid columns={{ base: 1, md: 3 }} spacing={16}>
                        <Area
                            icon={humanCentredImg}
                            title={"Human-Centred AI"}
                            text={
                                "Human-Centred AI involves designing and developing AI-driven systems or technologies that prioritize the needs, preferences, and experiences of users. In the area of health, for example, the goal is to create artificial intelligence (AI) technologies that enhance healthcare delivery, promote well-being, and support personalized care while maintaining ethical standards and human autonomy."
                            }
                        />
                        <Area
                            icon={adaptSysImg}
                            title={"User Adaptive Systems"}
                            text={
                                "User Adaptive Systems are interactive systems (e.g., mobile apps, web apps, augmented & virtual reality apps and games) that tailor or adjust functionalities and interventions in response to individual user characteristics and changing contexts. By continuously learning and adapting while leveraging artificial intelligence techniques (e.g., deep learning and natural language processing), these systems aim to provide personalized experiences that enhance effectiveness, usability, and user engagement."
                            }
                            wh={"250px 200px"}
                            bgColor="gray.50"
                        />
                        <Area
                            icon={persuasiveSysImg}
                            title={"Persuasive Technologies"}
                            text={
                                "Persuasive Technologies are interactive systems intentionally designed to change people's attitudes/behaviours without resorting to coercion or deception. These technologies operationalize various behaviour change strategies from psychology, design principles, and social sciences to promote desirable behaviours in users and groups, often aiming to improve health and well-being, promote environmental sustainability, or achieve other positive outcomes."
                            }
                            bgColor="gray.50"
                        />
                        <Area
                            icon={userModelImg}
                            title={"Dynamic User Modelling"}
                            text={
                                "Dynamic User Modelling involves creating user representations that continuously updates and adapts to reflect an individual user's changing behaviours, preferences, and contexts over time. Unlike static models that rely on fixed data points, dynamic user models incorporate real-time data to provide more personalized and relevant experiences. Focus is on developing dynamic user models using modern approaches including artificial intelligence (AI), multimodality, and sensor technologies to power personalized and user-adaptive systems."
                            }
                            wh={"200px 210px"}
                            bgColor="gray.50"
                        />
                        <Area
                            icon={intDesignImg}
                            title={"Intervention Design"}
                            text={
                                "Intervention design is a systematic and iterative process of planning, developing, and evaluating behaviour change interventions. Key steps include defining the problem, identifying target behaviours and influence factors, selecting and operationalizing appropriate behaviour change strategies, developing interventions that are grounded in theory and evidence-based, and evaluating the interventions to assess their effectiveness. The aim is to develop effective and sustainable interventions that address behaviour determinants and lead to desirable behaviour change in individuals and groups."
                            }
                            wh={"250px 210px"}
                            bgColor="gray.50"
                        />
                        <Area
                            icon={digitalHealthImg}
                            title={"Digital Health"}
                            text={
                                "Digital Health combines digital technologies with healthcare to enhance the efficacy, accessibility, and personalization of health services. It includes the use of mobile health apps, telemedicine, wearable sensors and devices, electronic health records, and artificial intelligence to improve health outcomes and wellness. The aim is to contribute towards the realization of United Nation's Sustainable Development Goal 3 by making healthcare and medicine more precise and patient-centered, promoting preventive care, empowering individuals to take control of their physical and mental health, and promoting equitable access to healthcare."
                            }
                            wh={"300px 200px"}
                            bgColor="gray.50"
                        />
                    </SimpleGrid>
                </Box>
            </Container>
        </Box>
    )
}