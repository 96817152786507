import React from "react";
import { Button, HStack, Tooltip, Tag } from "@chakra-ui/react";
import { HiOutlineMail } from "react-icons/hi";
import { RiLinkedinFill, RiFacebookFill, RiInstagramLine, RiGraduationCapFill, RiTwitterXFill } from "react-icons/ri";

const CustomCard = React.forwardRef(({ children, ...rest }, ref) => (
    <Tag bg={"transparent"} ref={ref} {...rest}>
        {children}
    </Tag>
))

export const Social = () => {
    return (
        <HStack>
            <Tooltip hasArrow label='Email'>
                <Button px={0} variant={"outline"} as={"a"} href="mailto: oladapo.oyebode@dal.ca">
                    <CustomCard>
                        <HiOutlineMail size={"22px"} />
                    </CustomCard>
                </Button>
            </Tooltip>
            <Tooltip hasArrow label='LinkedIn'>
                <Button px={0} variant={"outline"} as={"a"} href="https://www.linkedin.com/in/oladapooyebode" target="_blank">
                    <CustomCard>
                        <RiLinkedinFill size={"22px"} />
                    </CustomCard>
                </Button>
            </Tooltip>
            <Tooltip hasArrow label='X (formerly Twitter)'>
                <Button px={0} variant={"outline"} as={"a"} href="https://www.x.com/oladapooyebode/" target="_blank">
                    <CustomCard>
                        <RiTwitterXFill size={"20px"} />
                    </CustomCard>
                </Button>
            </Tooltip>
            <Tooltip hasArrow label='Facebook'>
                <Button px={0} variant={"outline"} as={"a"} href="https://www.facebook.com/ooyebode/" target="_blank">
                    <CustomCard>
                        <RiFacebookFill size={"22px"} />
                    </CustomCard>
                </Button>
            </Tooltip>
            <Tooltip hasArrow label='Instagram'>
                <Button px={0} variant={"outline"} as={"a"} href="https://www.instagram.com/oladapo.oyebode/" target="_blank">
                    <CustomCard>
                        <RiInstagramLine size={"22px"} />
                    </CustomCard>
                </Button>
            </Tooltip>
            <Tooltip hasArrow label='Google Scholar'>
                <Button px={0} variant={"outline"} as={"a"} href="https://scholar.google.com/citations?user=-6DsTnMAAAAJ&hl=en&oi=ao" target="_blank">
                    <CustomCard>
                        <RiGraduationCapFill size={"22px"} />
                    </CustomCard>
                </Button>
            </Tooltip>
        </HStack>
    )
}