import { Box, Container, Stack, Text } from "@chakra-ui/react"
import { NavBar } from "../components/navbar"


const Reference = ({ payload, type = "book" }) => {
    let authors = "";
    let ref = "";

    authors = payload.authors.join(", ");

    if (type === "book")
        ref =
            <Box ml={6} >
                {authors}, "{payload.title}," in <i>{payload.bookTitle}</i>, <i>{payload.editors.join(", ")}, Eds.</i>, {payload.publisher}, {payload.year}, doi: <a href={payload.doi} target="_blank" rel="noreferrer" style={{ color: "blue" }}>{payload.doiText}</a>
            </Box>
    else if (type === "jour") {
        const pub = `${payload.publisher ? ", " + payload.publisher : ""}`;
        const vol = `${payload.volume ? ", vol. " + payload.volume : ""}`;
        const iss = `${payload.issue ? ", no. " + payload.issue : ""}`;
        const pgs = `${payload.pages ? ", pp. " + payload.pages : ""}`;

        ref =
            <Box ml={6} >
                {authors}, "{payload.title}," in <i>{payload.journal}</i>{pub}{vol}{iss}{pgs}, {payload.year}{payload.doi ? ", doi: " : ""}{payload.doi ? <a href={payload.doi} target="_blank" rel="noreferrer" style={{ color: "blue" }}>{payload.doiText}</a> : ""}
            </Box>
    }
    else if (type === "conf") {
        const loc = `${payload.location ? ", " + payload.location : ""}`;
        const pgs = `${payload.pages ? ", pp. " + payload.pages : ""}`;

        ref =
            <Box ml={6} >
                {authors}, "{payload.title}," in <i>{payload.proceeding}</i>{loc}{pgs}, {payload.year}{payload.doi ? ", doi: " : ""}{payload.doi ? <a href={payload.doi} target="_blank" rel="noreferrer" style={{ color: "blue" }}>{payload.doiText}</a> : ""}{payload.url ? ", " : ""}{payload.url ? <a href={payload.url} target="_blank" rel="noreferrer" style={{ color: "blue" }}>{payload.url}</a> : ""}
            </Box>
    }
    else if (type === "post") {
        const loc = `${payload.location ? ", " + payload.location : ""}`;
        const pgs = `${payload.pages ? ", pp. " + payload.pages : ""}`;

        ref =
            <Box ml={6} >
                {authors}, "{payload.title}," in <i>{payload.proceeding}</i>{loc}{pgs}, {payload.year}{payload.doi ? ", doi: " : ""}{payload.doi ? <a href={payload.doi} target="_blank" rel="noreferrer" style={{ color: "blue" }}>{payload.doiText}</a> : ""}{payload.url ? ", " : ""}{payload.url ? <a href={payload.url} target="_blank" rel="noreferrer" style={{ color: "blue" }}>{payload.url}</a> : ""}
            </Box>
    }


    return (
        <>
            {ref}
        </>
    )
}

export const Publications = () => {
    const bookChapters = [
        {
            id: 1,
            authors: [
                "R. Orji",
                "O. Oyebode",
                "A. Alslaity"
            ],
            year: 2024,
            title: "Persuasive Interfaces",
            bookTitle: "Usability, Inclusion and Sustainability in Human-Computer Interaction",
            editors: [
                "C. Stephanidis",
                "G. Salvendy"
            ],
            postTitle: "",
            publisher: "CRC Press, Taylor & Francis Group",
            pages: "",
            doiText: "10.1201/9781003495147",
            doi: "https://doi.org/10.1201/9781003495147"
        },
        {
            id: 2,
            authors: [
                "A. Alslaity",
                "O. Oyebode",
                "J. Vassileva",
                "R. Orji"
            ],
            year: 2024,
            title: "Personalized Persuasive Technologies in Health and Wellness: From Theory to Practice",
            bookTitle: "A Human-centered Perspective of Intelligent Personalized Environments and Systems",
            editors: [
                "B. Ferwerda",
                "M. Graus",
                "P. Germanakos",
                "M. Tkalcic"
            ],
            postTitle: "Human-Computer Interaction Series",
            publisher: "Springer",
            pages: "",
            doiText: "10.1007/978-3-031-55109-3_10",
            doi: "https://doi.org/10.1007/978-3-031-55109-3_10"
        }
    ]

    const journalPapers = [
        {
            id: 3,
            authors: [
                "O. Oyebode", "D. Steeves", "R. Orji"
            ],
            year: 2024,
            title: "Persuasive Strategies and Emotional States: Towards Designing Personalized and Emotion-Adaptive Persuasive Systems",
            journal: "User Modeling and User-Adapted Interaction",
            publisher: "Springer",
            volume: 34,
            issue: null,
            pages: "1175-1225",
            doiText: "10.1007/s11257-023-09390-x",
            doi: "https://doi.org/10.1007/s11257-023-09390-x"
        },
        {
            id: 4,
            authors: [
                "G. Chan", "B. Bilikis", "S. Anukem", "M. Imran", "S. Meena", "C. Nwagu", "O. Oyebode", "A. Alslaity", "A. Arya", "R. Orji"
            ],
            year: 2024,
            title: "Social Exergames in Health and Wellness: A Systematic Review of Trends, Effectiveness, Challenges, and Directions for Future Research",
            journal: "International Journal of Human Computer Interaction",
            publisher: "Springer",
            volume: null,
            issue: null,
            pages: "",
            doiText: "10.1080/10447318.2024.2371686",
            doi: "https://doi.org/10.1080/10447318.2024.2371686"
        },
        {
            id: 5,
            authors: [
                "S. Weerasinghe", "O. Oyebode", "R. Orji", "S. Matwin"
            ],
            year: 2023,
            title: "Dynamics of Emotion Trends in Canadian Twitter Users during COVID-19 Confinement in relation to Caseloads: Artificial Intelligence-based Emotion Detection Approach",
            journal: "DIGITAL HEALTH",
            publisher: "Sage",
            volume: 9,
            issue: null,
            pages: "",
            doiText: "10.1177/20552076231171496",
            doi: "https://doi.org/10.1177/20552076231171496"
        },
        {
            id: 6,
            authors: [
                "H. Jelodar", "R. Orji", "S. Matwin", "S. Weerasinghe", "O. Oyebode", "Y. Wang"
            ],
            year: 2023,
            title: "Emotion Detection and Semantic Trends during COVID-19 Social Isolation using Artificial Intelligence Techniques",
            journal: "Journal of Ambient Intelligence and Humanized Computing",
            publisher: "Springer",
            volume: 14,
            issue: null,
            pages: "16985-16993",
            doiText: "10.1007/s12652-023-04712-8",
            doi: "https://doi.org/10.1007/s12652-023-04712-8"
        },
        {
            id: 7,
            authors: [
                "O. Oyebode", "R. Orji"
            ],
            year: 2022,
            title: "Persuasive Strategy Implementation Choices and their Effectiveness: Towards Personalised Persuasive Systems",
            journal: "Behaviour & Information Technology",
            publisher: "Taylor & Francis Group",
            volume: 42,
            issue: 13,
            pages: "2176-2209",
            doiText: "10.1080/0144929X.2022.2112612",
            doi: "https://doi.org/10.1080/0144929X.2022.2112612"
        },
        {
            id: 8,
            authors: [
                "O. Oyebode", "J. Fowles", "D. Steeves", "R. Orji"
            ],
            year: 2022,
            title: "Machine Learning Techniques for Adaptive and Personalized Systems for Health and Wellness",
            journal: "International Journal of Human-Computer Interaction (IJHCI)",
            publisher: "Taylor & Francis Group",
            volume: 39,
            issue: 9,
            pages: "1938-1962",
            doiText: "10.1080/10447318.2022.2089085",
            doi: "https://doi.org/10.1080/10447318.2022.2089085"
        },
        {
            id: 9,
            authors: [
                "A. Alslaity", "B. Suruliraj", "O. Oyebode", "J. Fowles", "D. Steeves", "R. Orji"
            ],
            year: 2022,
            title: "Mobile Applications for Health and Wellness: A Systematic Review",
            journal: "ACM on Human-Computer Interaction",
            publisher: "",
            volume: 6,
            issue: null,
            pages: "1-29",
            doiText: "10.1145/3534525",
            doi: "https://doi.org/10.1145/3534525"
        },
        {
            id: 10,
            authors: [
                "M. Alhasani", "D. Mulchandani", "O. Oyebode", "N. Baghaei", "R. Orji"
            ],
            year: 2022,
            title: "A Systematic and Comparative Review of Behaviour Change Strategies in Stress Management Apps: Opportunities for Improvement",
            journal: "Frontiers in Public Health",
            publisher: "",
            volume: 10,
            issue: null,
            pages: "777567",
            doiText: "10.3389/fpubh.2022.777567",
            doi: "https://doi.org/10.3389/fpubh.2022.777567"
        },
        {
            id: 11,
            authors: [
                "C. Ndulue", "O. Oyebode", "R. S. Iyer", "A. Ganesh", "A. Adib", "S. I. Ahmed", "R. Orji"
            ],
            year: 2022,
            title: "Personality-targeted Persuasive Gamified Systems: Exploring the Impact of Application Domain on the Effectiveness of Behavior Change Strategies",
            journal: "User Modeling and User-Adapted Interaction (UMUAI)",
            publisher: "",
            volume: 32,
            issue: null,
            pages: "165-214",
            doiText: "10.1007/s11257-022-09319-w",
            doi: "https://doi.org/10.1007/s11257-022-09319-w"
        },
        {
            id: 12,
            authors: [
                "O. Oyebode", "C. Ndulue", "D. Mulchandani", "B. Suruliraj", "A. Adib", "F. A. Orji", "E. Milios", "S. Matwin", "R. Orji"
            ],
            year: 2022,
            title: "COVID-19 Pandemic: Identifying Key Issues using Social Media and Natural Language Processing",
            journal: "Journal of Healthcare Informatics Research",
            publisher: "",
            volume: 6,
            issue: null,
            pages: "174-207",
            doiText: "10.1007/s41666-021-00111-w",
            doi: "https://doi.org/10.1007/s41666-021-00111-w"
        },
        {
            id: 13,
            authors: [
                "A. Oduntan", "O. Oyebode", "A. H. Beltran", "J. Fowles", "D. Steeves", "R. Orji"
            ],
            year: 2022,
            title: "I Let Depression and Anxiety Drown Me: Identifying Factors Associated with Resilience Based on Journaling using Machine Learning and Thematic Analysis",
            journal: "IEEE Journal of Biomedical and Health Informatics",
            publisher: "",
            volume: 26,
            issue: 7,
            pages: "3397-3408",
            doiText: "10.1109/JBHI.2022.3149862",
            doi: "https://doi.org/10.1109/JBHI.2022.3149862"
        },
        {
            id: 14,
            authors: [
                "O. Oyebode", "R. Lomotey", "R. Orji"
            ],
            year: 2021,
            title: "\"I Tried to Breastfeed but…\": Exploring Factors Influencing Breastfeeding Behaviours Based on Tweets using Machine Learning and Thematic Analysis",
            journal: "IEEE Access",
            publisher: "",
            volume: 9,
            issue: null,
            pages: "61074-61089",
            doiText: "10.1109/ACCESS.2021.3073079",
            doi: "https://doi.org/10.1109/ACCESS.2021.3073079"
        },
        {
            id: 15,
            authors: [
                "O. Oyebode", "C. Ndulue", "A. Adib", "D. Mulchandani", "B. Suruliraj", "F. A. Orji", "C. T. Chambers", "S. Meier", "R. Orji"
            ],
            year: 2021,
            title: "Health, Psychosocial, and Social Issues Emanating From the COVID-19 Pandemic Based on Social Media Comments: Text Mining and Thematic Analysis Approach",
            journal: "JMIR Medical Informatics",
            publisher: "",
            volume: 9,
            issue: 4,
            pages: "e22734",
            doiText: "10.2196/22734",
            doi: "https://doi.org/10.2196/22734"
        },
        {
            id: 16,
            authors: [
                "O. Oyebode", "F. Alqahtani", "R. Orji"
            ],
            year: 2020,
            title: "Using Machine Learning and Thematic Analysis Methods to Evaluate Mental Health Apps Based on User Reviews",
            journal: "IEEE Access",
            publisher: "",
            volume: 8,
            issue: null,
            pages: "111141-111158",
            doiText: "10.1109/ACCESS.2020.3002176",
            doi: "https://doi.org/10.1109/ACCESS.2020.3002176"
        },
        {
            id: 17,
            authors: [
                "T. Olagunju", "O. Oyebode", "R. Orji"
            ],
            year: 2020,
            title: "Exploring Key Issues Affecting African Mobile eCommerce Applications Using Sentiment and Thematic Analysis",
            journal: "IEEE Access",
            publisher: "",
            volume: 8,
            issue: null,
            pages: "114475-114486",
            doiText: "10.1109/ACCESS.2020.3000093",
            doi: "https://doi.org/10.1109/ACCESS.2020.3000093"
        },
        {
            id: 18,
            authors: [
                "O. Oyebode", "R. Orji"
            ],
            year: 2020,
            title: "A Hybrid Recommender System for Product Sales in a Banking Environment",
            journal: "Journal of Banking and Financial Technology",
            publisher: "",
            volume: 4,
            issue: null,
            pages: "15-25",
            doiText: "10.1007/s42786-019-00014-w",
            doi: "https://doi.org/10.1007/s42786-019-00014-w"
        },
        {
            id: 19,
            authors: [
                "N. Aldenaini", "O. Oyebode", "R. Orji", "S. Sampalli"
            ],
            year: 2020,
            title: "Mobile Phone-Based Persuasive Technology for Physical Activity and Sedentary Behavior: A Systematic Review",
            journal: "Frontiers in Computer Science",
            publisher: "",
            volume: 2,
            issue: 19,
            pages: "",
            doiText: "10.3389/fcomp.2020.00019",
            doi: "https://doi.org/10.3389/fcomp.2020.00019"
        },
        {
            id: 20,
            authors: [
                "F. Alqahtani", "G. Al Khalifah", "O. Oyebode", "R. Orji"
            ],
            year: 2019,
            title: "Apps for Mental Health: An Evaluation of Behavior Change Strategies and Recommendations for Future Development",
            journal: "Frontiers in Artificial Intelligence",
            publisher: "",
            volume: 2,
            issue: 30,
            pages: "1-11",
            doiText: "10.3389/frai.2019.00030",
            doi: "https://doi.org/10.3389/frai.2019.00030"
        },
    ]

    const conferencePapers = [
        {
            id: 21,
            authors: [
                "O. Oyebode", "R. Ogubuike", "D. Daniel", "R. Orji"
            ],
            year: 2024,
            title: "Emotion Detection from Real-Life Situations based on Journal Entries using Machine Learning and Deep Learning Techniques",
            proceeding: "Intelligent Systems Conference (IntelliSys 2023)",
            location: "Amsterdam, Netherlands",
            pages: "477–502",
            doiText: "10.1007/978-3-031-47724-9_32",
            doi: "https://doi.org/10.1007/978-3-031-47724-9_32",
            url: null
        },
        {
            id: 22,
            authors: [
                "M. Alhasani", "O. Oyebode", "R. Orji"
            ],
            year: 2023,
            title: "SereneMind: Design and Evaluation of a Persuasive Mobile App for Managing Stress Among Adults",
            proceeding: "2023 IEEE 11th International Conference on Serious Games and Applications for Health (SeGAH)",
            location: "Athens, Greece",
            pages: "1-8",
            doiText: "10.1109/SeGAH57547.2023.10253762",
            doi: "https://doi.org/10.1109/SeGAH57547.2023.10253762",
            url: null
        },
        {
            id: 23,
            authors: [
                "O. Oyebode", "D. Steeves", "R. Orji"
            ],
            year: 2023,
            title: "Persuasive Strategies and Emotional States: Towards Emotion-Adaptive Persuasive Technologies Design",
            proceeding: "International Conference on Persuasive Technology (PERSUASIVE 2023)",
            location: "Eindhoven, Netherlands",
            pages: "215-233",
            doiText: "10.1007/978-3-031-30933-5_14",
            doi: "https://doi.org/10.1007/978-3-031-30933-5_14",
            url: null
        },
        {
            id: 24,
            authors: [
                "O. Oyebode", "F. Alqahtani", "R. Orji"
            ],
            year: 2022,
            title: "Exploring for Possible Effect of Persuasive Strategy Implementation Choices: Towards Tailoring Persuasive Technologies",
            proceeding: "International Conference on Persuasive Technology (PERSUASIVE 2022)",
            location: "Doha, Qatar",
            pages: "145-163",
            doiText: "10.1007/978-3-030-98438-0_12",
            doi: "https://doi.org/10.1007/978-3-030-98438-0_12",
            url: null
        },
        {
            id: 25,
            authors: [
                "O. Oyebode", "R. Orji"
            ],
            year: 2022,
            title: "Player Matching in a Persuasive Mobile Exergame: Towards Performance-Driven Collaboration and Adaptivity",
            proceeding: "International Conference on Persuasive Technology (PERSUASIVE 2022)",
            location: "Doha, Qatar",
            pages: "164-173",
            doiText: "10.1007/978-3-030-98438-0_13",
            doi: "https://doi.org/10.1007/978-3-030-98438-0_13",
            url: null
        },
        {
            id: 26,
            authors: [
                "O. Oyebode", "M. Alhasani", "D. Mulchandani", "T. Olagunju", "R. Orji"
            ],
            year: 2021,
            title: "SleepFit: A Persuasive Mobile App for Improving Sleep Habits in Young Adults",
            proceeding: "2021 IEEE 9th International Conference on Serious Games and Applications for Health (SeGAH)",
            location: "Dubai, United Arab Emirates",
            pages: "1-8",
            doiText: "10.1109/SEGAH52098.2021.9551907",
            doi: "https://doi.org/10.1109/SEGAH52098.2021.9551907",
            url: null
        },
        {
            id: 27,
            authors: [
                "O. Oyebode", "R. Orji"
            ],
            year: 2021,
            title: "MediNER: Understanding Diabetes Management Strategies Based on Social Media Discourse",
            proceeding: "2021 IEEE Intl Conf on Parallel & Distributed Processing with Applications, Big Data & Cloud Computing, Sustainable Computing & Communications, Social Computing & Networking (ISPA/BDCloud/SocialCom/SustainCom)",
            location: "NY, USA",
            pages: "1546-1553",
            doiText: "10.1109/ISPA-BDCloud-SocialCom-SustainCom52081.2021.00208",
            doi: "https://doi.org/10.1109/ISPA-BDCloud-SocialCom-SustainCom52081.2021.00208",
            url: null
        },
        {
            id: 28,
            authors: [
                "B. Graham-Kalio", "O. Oyebode", "N. Zincir-Heywood", "R. Orji"
            ],
            year: 2021,
            title: "Analyzing COVID-19 Tweets using Health Behaviour Theories and Machine Learning",
            proceeding: "2021 IEEE 9th International Conference on Serious Games and Applications for Health (SeGAH)",
            location: "Dubai, United Arab Emirates",
            pages: "1-8",
            doiText: "10.1109/SEGAH52098.2021.9551908",
            doi: "https://doi.org/10.1109/SEGAH52098.2021.9551908",
            url: null
        },
        {
            id: 29,
            authors: [
                "O. Oyebode", "A. Ganesh", "R. Orji"
            ],
            year: 2021,
            title: "TreeCare: Development and Evaluation of a Persuasive Mobile Game for Promoting Physical Activity",
            proceeding: "2021 IEEE Conference on Games (CoG)",
            location: "Copenhagen, Denmark",
            pages: "1-8",
            doiText: "10.1109/CoG52621.2021.9619035",
            doi: "https://doi.org/10.1109/CoG52621.2021.9619035",
            url: null
        },
        {
            id: 30,
            authors: [
                "O. Oyebode", "C. Ndulue", "D. Mulchandani", "A. A. Z. Adib", "M. Alhasani", "R. Orji"
            ],
            year: 2021,
            title: "Tailoring Persuasive and Behaviour Change Systems Based on Stages of Change and Motivation",
            proceeding: "CHI Conference on Human Factors in Computing Systems (CHI '21)",
            location: "Yokohama, Japan",
            pages: "1-19",
            doiText: "10.1145/3411764.3445619",
            doi: "https://doi.org/10.1145/3411764.3445619",
            url: null
        },
        {
            id: 31,
            authors: [
                "O. Oyebode", "D. Maurya", "R. Orji"
            ],
            year: 2020,
            title: "Nourish Your Tree! Developing a Persuasive Exergame for Promoting Physical Activity Among Adults",
            proceeding: "8th IEEE International Conference on Serious Games and Applications for Health (SeGAH 2020)",
            location: "Vancouver, BC, Canada",
            pages: "1-7",
            doiText: "10.1109/SeGAH49190.2020.9201637",
            doi: "https://doi.org/10.1109/SeGAH49190.2020.9201637",
            url: null
        },
        {
            id: 32,
            authors: [
                "O. Oyebode", "C. Ndulue", "M. Alhasani", "R. Orji"
            ],
            year: 2020,
            title: "Persuasive Mobile Apps for Health and Wellness: A Comparative Systematic Review",
            proceeding: "International Conference on Persuasive Technology (PERSUASIVE 2020)",
            location: "Aalborg, Denmark",
            pages: "163-181",
            doiText: "10.1007/978-3-030-45712-9_13",
            doi: "https://doi.org/10.1007/978-3-030-45712-9_13",
            url: null
        },
        {
            id: 33,
            authors: [
                "O. Oyebode", "B. Graham-Kalio", "R. Orji"
            ],
            year: 2020,
            title: "HeartHealth: A Persuasive Mobile App for Mitigating the Risk of Ischemic Heart Disease",
            proceeding: "International Conference on Persuasive Technology (PERSUASIVE 2020)",
            location: "Aalborg, Denmark",
            pages: "126-138",
            doiText: "10.1007/978-3-030-45712-9_10",
            doi: "https://doi.org/10.1007/978-3-030-45712-9_10",
            url: null
        },
        {
            id: 34,
            authors: [
                "C. Ndulue", "O. Oyebode", "R. Orji"
            ],
            year: 2020,
            title: "PHISHER CRUSH: A Mobile Persuasive Game for Promoting Online Security",
            proceeding: "International Conference on Persuasive Technology (PERSUASIVE 2020)",
            location: "Aalborg, Denmark",
            pages: "223-233",
            doiText: "10.1007/978-3-030-45712-9_17",
            doi: "https://doi.org/10.1007/978-3-030-45712-9_17",
            url: null
        },
        {
            id: 35,
            authors: [
                "O. Oyebode", "R. Orji"
            ],
            year: 2019,
            title: "Social Media and Sentiment Analysis: The Nigeria Presidential Election 2019",
            proceeding: "10th Annual Information Technology, Electronics and Mobile Communication Conference (IEMCON)",
            location: "Vancouver, BC, Canada",
            pages: "140-146",
            doiText: "10.1109/IEMCON.2019.8936139",
            doi: "https://doi.org/10.1109/IEMCON.2019.8936139",
            url: null
        },
        {
            id: 36,
            authors: [
                "O. Oyebode", "R. Orji"
            ],
            year: 2019,
            title: "Detecting Factors Responsible for Diabetes Prevalence in Nigeria using Social Media and Machine Learning",
            proceeding: "International Conference on Network and Service Management (CNSM)",
            location: "Halifax, NS, Canada",
            pages: "1-4",
            doiText: "10.23919/CNSM46954.2019.9012679",
            doi: "https://doi.org/10.23919/CNSM46954.2019.9012679",
            url: null
        },
        {
            id: 37,
            authors: [
                "O. Oyebode", "R. Orji"
            ],
            year: 2018,
            title: "BAM framework: unlocking highly personalized banking services in Africa",
            proceeding: "Second African Conference for Human Computer Interaction: Thriving Communities (AfriCHI '18)",
            location: "Windhoek, Namibia",
            pages: "1-4",
            doiText: "10.1145/3283458.3283526",
            doi: "https://doi.org/10.1145/3283458.3283526",
            url: null
        },
        {
            id: 38,
            authors: [
                "I. Adaji", "K. Oyibo", "R. Orji", "J. Ham", "O. Oyebode"
            ],
            year: 2022,
            title: "Preface to the 6th International Workshop on Personalizing Persuasive Technologies",
            proceeding: "Adjunct Proceedings of the 17th International Conference on Persuasive Technology (PERSUASIVE 2022)",
            location: "Doha, Qatar",
            pages: "1-4",
            doiText: "",
            doi: null,
            url: "https://ceur-ws.org/Vol-3153/preface2.pdf"
        },
        {
            id: 39,
            authors: [
                "O. Oyebode", "R. Orji"
            ],
            year: 2020,
            title: "Deconstructing Persuasive Strategies in Mental Health Apps Based on User Reviews using Natural Language Processing",
            proceeding: "International Workshop on Behavior Change Support Systems (BCSS 2020)",
            location: "Aalborg, Denmark",
            pages: "1-13",
            doiText: "",
            doi: null,
            url: "http://ceur-ws.org/Vol-2662/BCSS2020_paper5.pdf"
        },
        {
            id: 40,
            authors: [
                "M. Alhasani", "D. Mulchandani", "O. Oyebode", "R. Orji"
            ],
            year: 2020,
            title: "A Systematic Review of Persuasive Strategies in Stress Management Apps",
            proceeding: "International Workshop on Behavior Change Support Systems (BCSS 2020)",
            location: "Aalborg, Denmark",
            pages: "1-13",
            doiText: "",
            doi: null,
            url: "http://ceur-ws.org/Vol-2662/BCSS2020_paper4.pdf"
        },
        {
            id: 41,
            authors: [
                "R. Orji", "J. Ham", "K. Oyibo", "J. Nwokeji", "O. Oyebode"
            ],
            year: 2020,
            title: "Personalizing Persuasive Technologies Workshop 2020",
            proceeding: "Adjunct Proceedings of the 15th International Conference on Persuasive Technology (PERSUASIVE 2020)",
            location: "Aalborg, Denmark",
            pages: "1-5",
            doiText: "",
            doi: null,
            url: "http://ceur-ws.org/Vol-2629/1_workshop_orji.pdf"
        },
    ]

    const posters = [
        {
            id: 42,
            authors: [
                "O. Oyebode", "R. Orji"
            ],
            year: 2020,
            title: "Persuasive Strategies in Mental Health Apps: A Natural Language Processing Approach",
            proceeding: "International Conference on Persuasive Technology (PERSUASIVE 2020), Adjunct Proceedings",
            location: "Aalborg, Denmark",
            pages: "1-4",
            doiText: "",
            doi: "https://doi.org/",
            url: "http://ceur-ws.org/Vol-2629/12_poster_oyebode.pdf"
        },
        {
            id: 43,
            authors: [
                "O. Oyebode", "R. Orji"
            ],
            year: 2020,
            title: "A Persuasive Mobile Game for Reducing Sedentary Behaviour and Promoting Physical Activity",
            proceeding: "International Conference on Persuasive Technology (PERSUASIVE 2020), Adjunct Proceedings",
            location: "Aalborg, Denmark",
            pages: "",
            doiText: "",
            doi: "https://doi.org/",
            url: "http://ceur-ws.org/Vol-2629/11_poster_oyebode.pdf"
        },
        {
            id: 44,
            authors: [
                "O. Oyebode", "R. Orji"
            ],
            year: 20,
            title: "Likita: A Medical Chatbot to Improve HealthCare Delivery in Africa",
            proceeding: "Extended Abstracts of the 2018 CHI Conference on Human Factors in Computing Systems, HCI Across Borders (HCIxB)",
            location: "Montreal QC, Canada",
            pages: "1-4",
            doiText: "",
            doi: "https://doi.org/",
            url: "http://www.hcixb.org/papers_2018/hcixb18-final-22.pdf"
        },
    ]

    return (
        <Box id="p">
            <NavBar selectedId={3} />
            <Container maxW={"6xl"} pb={20} mt={{ base: 8, md: 10 }}>
                <Text textAlign={"center"} fontSize={{ base: "2xl", md: "4xl" }} fontWeight={600}>Publications</Text>

                <Stack pt={{ base: 8, md: 12 }} spacing={4}>
                    <Text fontSize={"lg"} fontWeight={600}>Book Chapters</Text>
                    <Stack spacing={4}>
                        {
                            bookChapters.map((p, k) =>
                                <Reference payload={p} key={k} />
                            )
                        }
                    </Stack>
                </Stack>
                <Stack pt={8} spacing={4}>
                    <Text fontSize={"lg"} fontWeight={600}>Journal Papers</Text>
                    <Stack spacing={4}>
                        {
                            journalPapers.map((p, k) =>
                                <Reference payload={p} key={k} type="jour" />
                            )
                        }
                    </Stack>
                </Stack>
                <Stack pt={8} spacing={4}>
                    <Text fontSize={"lg"} fontWeight={600}>Conference Papers</Text>
                    <Stack spacing={4}>
                        {
                            conferencePapers.map((p, k) =>
                                <Reference payload={p} key={k} type="conf" />
                            )
                        }
                    </Stack>
                </Stack>
                <Stack pt={8} spacing={4}>
                    <Text fontSize={"lg"} fontWeight={600}>Posters</Text>
                    <Stack spacing={4}>
                        {
                            posters.map((p, k) =>
                                <Reference payload={p} key={k} type="post" />
                            )
                        }
                    </Stack>
                </Stack>
            </Container>
        </Box>
    )
}