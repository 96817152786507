"use client"
import {
    Box,
    Flex,
    Text,
    IconButton,
    Stack,
    Collapse,
    Icon,
    Popover,
    PopoverTrigger,
    PopoverContent,
    useColorModeValue,
    useBreakpointValue,
    useDisclosure
} from "@chakra-ui/react"
import { RxHamburgerMenu as HamburgerIcon } from "react-icons/rx";
import { IoCloseOutline as CloseIcon, IoChevronDownOutline as ChevronDownIcon, IoChevronForwardOutline as ChevronRightIcon } from "react-icons/io5";
import '@fontsource/pacifico';
import { useNavigate } from "react-router-dom";
import cvFile from "../assets/files/CV_Oyebode.pdf";

export function NavBar({ selectedId = 0 }) {
    const { isOpen, onToggle } = useDisclosure()
    const navigate = useNavigate();

    return (
        <Box>
            <Flex
                bg={useColorModeValue("white", "gray.800")}
                color={useColorModeValue("gray.600", "white")}
                minH={"60px"}
                py={{ base: 2 }}
                px={{ base: 4, md: 28 }}
                borderBottom={1}
                borderStyle={"solid"}
                borderColor={useColorModeValue("gray.200", "gray.900")}
                align={"center"}
            >
                <Flex
                    flex={{ base: 1, md: "auto" }}
                    ml={{ base: -2 }}
                    display={{ base: "flex", md: "none" }}
                >
                    <IconButton
                        onClick={onToggle}
                        icon={
                            isOpen ? <CloseIcon size={"26px"} /> : <HamburgerIcon size={"24px"} />
                        }
                        variant={"ghost"}
                        aria-label={"Toggle Navigation"}
                    />
                </Flex>
                <Flex flex={{ base: 2 }} justify={{ base: "left", md: "space-between" }} alignItems={"center"}>
                    <Text
                        textAlign={useBreakpointValue({ base: "left", md: "left" })}
                        fontFamily={"pacifico"}
                        color={useColorModeValue("gray.800", "white")}
                        fontSize={{ base: "22px", md: "2xl" }}
                        cursor={"pointer"}
                        onClick={() => navigate("/")}
                    >
                        Oladapo Oyebode
                    </Text>

                    <Flex display={{ base: "none", md: "flex" }} ml={10}>
                        <DesktopNav selectedId={selectedId} />
                    </Flex>
                </Flex>
            </Flex>

            <Collapse in={isOpen} animateOpacity>
                <MobileNav />
            </Collapse>
        </Box>
    )
}

const DesktopNav = ({ selectedId = 0 }) => {
    const linkColor = useColorModeValue("gray.600", "gray.200")
    const linkHoverColor = useColorModeValue("blue.600", "white")
    const popoverContentBgColor = useColorModeValue("white", "gray.800")
    const navigate = useNavigate();

    return (
        <Stack direction={"row"} spacing={4}>
            {NAV_ITEMS.map(navItem => (
                <Box key={navItem.label}>
                    <Popover trigger={"hover"} placement={"bottom-start"}>
                        <PopoverTrigger>
                            {
                                navItem.id !== 2 ? <Box
                                    cursor={"pointer"}
                                    p={2}
                                    onClick={() => navigate(navItem.href ?? "/")}
                                    //to={navItem.href ?? "/"}
                                    fontSize={{ base: "sm", md: "md" }}
                                    fontWeight={500}
                                    color={navItem.id === selectedId ? linkHoverColor : linkColor}
                                    _hover={{
                                        textDecoration: "none",
                                        color: linkHoverColor
                                    }}
                                >
                                    {navItem.label}
                                </Box>
                                    :
                                    navItem.id === 2 && <Box mt={2}>
                                        <Box
                                            as="a"
                                            cursor={"pointer"}
                                            p={2}
                                            href={cvFile}
                                            fontSize={{ base: "sm", md: "md" }}
                                            fontWeight={500}
                                            color={navItem.id === selectedId ? linkHoverColor : linkColor}
                                            _hover={{
                                                textDecoration: "none",
                                                color: linkHoverColor
                                            }}
                                            download={"CV_Oyebode"}
                                        >
                                            {navItem.label}
                                        </Box>
                                    </Box>
                            }
                        </PopoverTrigger>

                        {navItem.children && (
                            <PopoverContent
                                border={0}
                                boxShadow={"xl"}
                                bg={popoverContentBgColor}
                                p={4}
                                rounded={"xl"}
                                minW={"sm"}
                            >
                                <Stack>
                                    {navItem.children.map(child => (
                                        <DesktopSubNav key={child.label} {...child} />
                                    ))}
                                </Stack>
                            </PopoverContent>
                        )}
                    </Popover>
                </Box>
            ))}
        </Stack>
    )
}

const DesktopSubNav = ({ label, href, subLabel }) => {
    return (
        <Box
            as="a"
            href={href}
            role={"group"}
            display={"block"}
            p={2}
            rounded={"md"}
            _hover={{ bg: useColorModeValue("pink.50", "gray.900") }}
        >
            <Stack direction={"row"} align={"center"}>
                <Box>
                    <Text
                        transition={"all .3s ease"}
                        _groupHover={{ color: "pink.400" }}
                        fontWeight={500}
                    >
                        {label}
                    </Text>
                    <Text fontSize={"sm"}>{subLabel}</Text>
                </Box>
                <Flex
                    transition={"all .3s ease"}
                    transform={"translateX(-10px)"}
                    opacity={0}
                    _groupHover={{ opacity: "100%", transform: "translateX(0)" }}
                    justify={"flex-end"}
                    align={"center"}
                    flex={1}
                >
                    <Icon color={"pink.400"} w={5} h={5} as={ChevronRightIcon} />
                </Flex>
            </Stack>
        </Box>
    )
}

const MobileNav = () => {
    return (
        <Stack
            bg={useColorModeValue("white", "gray.800")}
            p={4}
            display={{ md: "none" }}
        >
            {NAV_ITEMS.map(navItem => (
                <MobileNavItem key={navItem.label} {...navItem} />
            ))}
        </Stack>
    )
}

const MobileNavItem = ({ id, label, children, href }) => {
    const { isOpen, onToggle } = useDisclosure()
    const navigate = useNavigate();

    return (
        <Stack spacing={4} onClick={children && onToggle}>
            {
                id !== 2 ? <Box
                    py={2}
                    cursor={"pointer"}
                    onClick={() => navigate(href ?? "/")}
                    justifyContent="space-between"
                    alignItems="center"
                    _hover={{
                        textDecoration: "none"
                    }}
                >
                    <Text
                        fontWeight={600}
                        color={"gray.600"}
                    >
                        {label}
                    </Text>
                    {children && (
                        <Icon
                            as={ChevronDownIcon}
                            transition={"all .25s ease-in-out"}
                            transform={isOpen ? "rotate(180deg)" : ""}
                            w={6}
                            h={6}
                        />
                    )}
                </Box>
                    :
                    <Box py={2}>
                        <Box
                            as="a"
                            cursor={"pointer"}
                            href={cvFile}
                            justifyContent="space-between"
                            alignItems="center"
                            _hover={{
                                textDecoration: "none"
                            }}
                            download={"CV_Oyebode"}
                        >
                            <Text
                                fontWeight={600}
                                color={"gray.600"}
                            >
                                {label}
                            </Text>
                            {children && (
                                <Icon
                                    as={ChevronDownIcon}
                                    transition={"all .25s ease-in-out"}
                                    transform={isOpen ? "rotate(180deg)" : ""}
                                    w={6}
                                    h={6}
                                />
                            )}
                        </Box>
                    </Box>
            }


            <Collapse in={isOpen} animateOpacity style={{ marginTop: "0!important" }}>
                <Stack
                    mt={2}
                    pl={4}
                    borderLeft={1}
                    borderStyle={"solid"}
                    borderColor={useColorModeValue("gray.200", "gray.700")}
                    align={"start"}
                >
                    {children &&
                        children.map(child => (
                            <Box as="a" key={child.label} py={2} href={child.href}>
                                {child.label}
                            </Box>
                        ))}
                </Stack>
            </Collapse>
        </Stack>
    )
}

const NAV_ITEMS = [
    {
        id: 1,
        label: "Research Areas",
        href: "/research-areas",
        children: null
    },
    {
        id: 2,
        label: "CV",
        href: "../assets/files/CV_Oyebode.pdf",
        children: null
    },
    {
        id: 3,
        label: "Publications",
        href: "/publications#p",
        children: null
    },
    {
        id: 4,
        label: "Teaching",
        href: "/teaching",
        children: null
    },
    /* {
        id: 5,
        label: "Media",
        href: "/#",
        children: null
    }, */
    {
        id: 6,
        label: "Contact",
        href: "/contact",
        children: null
    }
]
