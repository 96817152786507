import { NavBar } from "../components/navbar";
import bannerImg from "../assets/images/banner-photo.png";
import headshotImg from "../assets/images/headshot.png";
import { Flex, Image, Button, Stack, Text, Box, Card, CardBody, CardFooter, VStack, HStack } from "@chakra-ui/react";
import '@fontsource/pacifico';
import cvFile from "../assets/files/CV_Oyebode.pdf";
import { HashLink } from "react-router-hash-link";
import { Social } from "../components/social";



export const Home = () => {
    return (
        <>
            <NavBar />
            <Flex w={'100vw'} h={{ base: '50vh', md: '70vh' }} backgroundImage={bannerImg} backgroundSize={'cover'} alignItems={"center"}>
                <Stack w={'100vw'} justify={"center"}>
                    <Box bg={'blackAlpha.400'} py={6}>
                        <Text as={"h1"} textAlign={"center"} color={"white"} fontSize={{ base: "3xl", md: '5xl' }} fontWeight={600}>Oladapo Oyebode, PhD</Text>
                        <Text as={"h2"} display={{ base: "none", md: "block" }} textAlign={"center"} color={"white"} fontSize={'3xl'} fontWeight={500}>Assistant Professor and Co-Director, Persuasive Computing Lab</Text>
                        <Text as={"h2"} display={{ md: "none" }} textAlign={"center"} color={"white"} fontSize={"lg"} fontWeight={500}>Assistant Professor and Co-Director, <br />Persuasive Computing Lab</Text>
                    </Box>
                </Stack>
            </Flex>
            <VStack py={{ base: 6, md: 10 }} alignItems={"center"}>
                <Text textAlign={"center"} fontWeight={600} fontSize={{ base: "xl", md: "2xl" }}>About Me</Text>
            </VStack>
            <VStack mb={20}>
                <Card
                    direction={{ base: 'column', md: 'row' }}
                    overflow='hidden'
                    variant='outline'
                    boxShadow={'2xl'}
                >
                    <Stack>
                        <CardBody>
                            <Stack fontSize={{ base: "md", md: "md" }}>
                                <Text py='2' w={{ base: "100%", md: '50vw' }}>
                                    I am an Assistant Professor in the < a href="https://www.dal.ca/faculty/computerscience/faculty-staff/Oladapo_Oyebode.html" target="_blank" style={{ color: "blue" }} rel="noreferrer">Faculty of Computer Science</a>, Dalhousie University, Canada. I obtained my PhD and MSc degrees in Computer Science from <a href="https://dal.ca" target="_blank" style={{ color: "blue" }} rel="noreferrer">Dalhousie University</a> and <a href="https://uwo.ca" target="_blank" style={{ color: "blue" }} rel="noreferrer">Western University</a>, Canada, respectively.
                                </Text>
                                <Text w={{ base: "100%", md: '50vw' }}>
                                    My <HashLink smooth to={"/research-areas#r"} style={{ color: "blue" }}>research</HashLink> intersects Human-Computer Interaction, Artificial Intelligence (AI), and Persuasive Computing to design, develop, and evaluate AI-driven, user-adaptive, and highly personalized technologies for social good across diverse domains including health and wellness. These technologies deliver evidence-based, personalized, and adaptive interventions in real-time while operationalizing appropriate behaviour change strategies to motivate and promote desirable behaviours in individuals and groups. Some of my research projects have led to award-winning publications and media recognitions.
                                </Text>
                                <Text pt={2} w={{ base: "100%", md: '50vw' }}>
                                    I have <HashLink smooth to={"/publications#p"} style={{ color: "blue" }}>published</HashLink> many peer-reviewed research articles in top international journals and conferences including prestigious and high-impact venues. I have also collaborated with academic and industry experts within and outside Canada on important research over the years. I have written reviews for diverse and top international journals and conferences, and also co-organized international workshops. In addition, I am in the editorial board for some reputable and well-established international journals. I have over 10 years industry experience across diverse Computer Science fields including software engineering, machine learning, natural language processing, data science, big data analytics, data architecture, data engineering, and cloud solutions. I also held leadership positions in the industry including Head of Innovation and Emerging Trends.
                                </Text>
                                <Text pt={2} w={{ base: "100%", md: '50vw' }}>
                                    Feel free to <HashLink smooth to={"/contact#c"} style={{ color: "blue" }}>reach out</HashLink> to me to discuss research and industry collaborations in areas of common interests :)
                                </Text>
                            </Stack>
                        </CardBody>
                        <CardFooter display={{ base: "none", md: "flex" }} justify={"space-between"} mt={-5}>
                            <HStack>
                                <Button as="a" href={cvFile} variant='solid' colorScheme='blue' download={"CV_Oyebode"}>
                                    My CV
                                </Button>
                                <Button as={HashLink} to="/research-areas#r" smooth variant='solid' colorScheme='blue'>
                                    Research Areas
                                </Button>
                                <Button as={HashLink} to="/publications#p" smooth variant='solid' colorScheme='blue'>
                                    Publications
                                </Button>
                            </HStack>
                            <Social />
                        </CardFooter>
                        <CardFooter display={{ md: "none" }} flexDir={"column"} mt={-5}>
                            <HStack>
                                <Button as="a" href={cvFile} variant='solid' colorScheme='blue' download={"CV_Oyebode"}>
                                    My CV
                                </Button>
                                <Button as={HashLink} to="/research-areas#r" smooth variant='solid' colorScheme='blue'>
                                    Research Areas
                                </Button>
                                <Button as={HashLink} to="/publications#p" smooth variant='solid' colorScheme='blue'>
                                    Publications
                                </Button>
                            </HStack>
                            <Box pt={4}>
                                <Social />
                            </Box>
                        </CardFooter>
                    </Stack>
                    <Image
                        objectFit='cover'
                        maxW={{ base: '100%', md: '500px' }}
                        src={headshotImg}
                        alt='Oladapo Oyebode, PhD'
                        bg={"gray.100"}
                    />
                </Card>
            </VStack>
        </>
    )
}