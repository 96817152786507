import { Container, Text, Stack, Box } from "@chakra-ui/react"
import { NavBar } from "../components/navbar"
import { Social } from "../components/social";

const AddressMap = () => {
    return (
        <Box>
            <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2838.6151383448123!2d-63.57577692241526!3d44.64578237107248!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4b5a22334ce5ecaf%3A0x3a5d2dba1a576baf!2s1577%20Barrington%20St%2C%20Halifax%2C%20NS%20B3J%201Z7!5e0!3m2!1sen!2sca!4v1733188925221!5m2!1sen!2sca"
                width={"100%"}
                height="450"
                frameborder="0"
                style={{ border: 0 }}
                allowfullscreen=""
                aria-hidden="false"
                tabindex="0"
                //loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
            />
        </Box>
    );
};

export const Contact = () => {
    return (
        <Box id="c">
            <NavBar selectedId={6} />
            <Container maxW={"6xl"} pb={20} mt={{ base: 8, md: 10 }}>
                <Text textAlign={"center"} fontSize={{ base: "2xl", md: "4xl" }} fontWeight={600}>Contact Information</Text>
                <Stack direction={{ base: "column", md: "row" }} pt={10} justify={"space-between"} spacing={12}>
                    <Stack fontSize={"lg"} spacing={4} w={{ base: "100%", md: "40%" }}>
                        <Stack>
                            <Text fontWeight={500}>Paramount Building, 4th Floor</Text>
                            <Text fontWeight={500}>1577 Barrington Street</Text>
                            <Text fontWeight={500}>Halifax, NS B3J 1Z7</Text>
                            <Text fontWeight={500}>Canada</Text>
                        </Stack>
                        <Text fontWeight={500}>
                            <Text as={"span"} fontWeight={300}>Email:</Text> <Text as={"a"} href="mailto: oladapo.oyebode@dal.ca" color={"blue.700"}>oladapo.oyebode@dal.ca</Text>
                        </Text>
                        <Social />
                    </Stack>
                    <Box w={{ base: "100%", md: "100%" }}>
                        <AddressMap />
                    </Box>
                </Stack>

            </Container>
        </Box>
    )
}